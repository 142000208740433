import { dirButton } from "../lib/utils";

const gallerySlider = {
  elem: {
    $slider: $(".gallery-slider .slider"),
  },

  options: {
    adaptiveHeight: true,
    arrows: true,
    dots: false,
    draggable: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 425,
    rows: 0,
    fade: true,
    lazyLoad: "progressive",
    nextArrow: dirButton(
      "",
      "dir-button--secondary dir-button--large",
      "Next slide"
    ),
    prevArrow: dirButton(
      "",
      "dir-button--secondary dir-button--prev dir-button--large",
      "Previous slide"
    ),
  },

  init() {
    this.bindUIActions();
  },

  bindUIActions() {
    this.elem.$slider
      .on("init", this.lazyLoad.bind(this))
      .on("afterChange", this.lazyLoad.bind(this));

    this.elem.$slider.slick(this.options);
    this.elem.$slider.on("mouseleave", ".dir-button", (e) =>
      $(e.target).blur()
    );
  },

  lazyLoad(event, slick) {
    let $image = $(slick.$slides[slick.currentSlide]).find(".image");

    if ($image.attr("data-url")) {
      let $img = $("<img />"); // Faux image

      let label = $image.attr("aria-label");
      let url = $image.data("url");

      $img.attr("src", url);
      $img.attr("alt", label);
      $img.attr("class", "is-hidden");

      $image.after($img);

      // On HTML img load, replace the div's data-url and add the style attribute that references the CSS background image (URL)
      // Note: a faux img is necessary in order to load the image in a proper timeframe as well as to communicate to Slick as to
      // when to fade the image (slide) into display using the lazyLoad property - sstacho
      $img.on("load", () => {
        $image.attr("style", `background-image: url('${url}');`);
        $image.removeAttr("data-url");
      });
    }
  },
};

export { gallerySlider };
