import { debounce } from "lodash-es";
import { getRandomNumber, mql, speechBubble } from "../lib/utils";

class Tooltip {
  constructor(element, options) {
    this.defaults = {
      // Defaults
      offset: 0, // Vertical offset
      type: "button",
      // Callback(s)
      before() {},
      after() {},
    };
    this.element = element;
    this.options = $.extend({}, this.defaults, options);

    this.init();
  }

  init() {
    this.bindUIActions();
  }

  bindUIActions() {
    $(this.element).on("click", this.toggle.bind(this));
    $(this.element).on("mouseleave", (e) => $(e.target).blur());

    $(window).on("resize", debounce(this.onResize.bind(this), 100));

    $(document).on("click", this.closeAllSpeechBubbles.bind(this));
    $(document).on("click", ".close-button", this.closeSpeechBubble.bind(this));
  }

  onResize() {
    let $speechbubble = $(this.element).next(".speech-bubble");

    if ($speechbubble && $speechbubble.length) {
      let isVisible = $speechbubble.hasClass("speech-bubble--is-visible");

      if ($(this.element).hasClass("text-tooltip text-tooltip--is-active")) {
        $(this.element).removeClass("text-tooltip--is-active");
      }

      $speechbubble.remove();

      if (isVisible) {
        $(this.element).trigger("click");
      }
    }
  }

  toggle(e, closeAll = true) {
    e.preventDefault();

    const $target = $(e.target);
    const hasSpeechBubble = $target.next(".speech-bubble").length;

    if (closeAll) {
      this.closeAllSpeechBubbles(null);
    }

    // Callback:before
    this.options.before.call(this, this.element);

    if (hasSpeechBubble) {
      $target.next(".speech-bubble").toggleClass("speech-bubble--is-visible");
    } else {
      const title = $target.data("tooltip-content");
      const id = "speech-bubble" + getRandomNumber();
      const speechbubble = speechBubble("", id, title);

      $target.attr("aria-describedby", id);
      $target.attr("aria-labelledby", id);

      $(speechbubble).insertAfter($target);

      // Wait till the speech-bubble is created and inserted into the DOM then determine its x and y coordinates and "show" it - sstacho
      let timer = setInterval(() => {
        let $speechbubble = $('.speech-bubble[id="' + id + '"]');

        if ($speechbubble.length) {
          let $form = $target.closest(".form");

          // Mobile/Desktop offset(s) for type (button, text)
          this.options.offset = mql.xlarge.matches
            ? this.options.type === "button"
              ? 16
              : 10
            : this.options.type === "button"
            ? 0
            : 0;

          let x = mql.xlarge.matches
            ? $target.position().left -
              ($speechbubble.outerWidth(true) / 2 -
                $target.outerWidth(true) / 2)
            : 0;
          let y =
            $target.position().top +
            ($target.outerHeight(true) + this.options.offset);

          // Form tooltip styling only!
          x = mql.xlarge.matches
            ? $form.length
              ? $target.position().left -
                $speechbubble.outerWidth(true) +
                ($target.outerWidth(true) + 10)
              : x
            : 0;

          $speechbubble.css("transform", `translate3d(${x}px, ${y}px, 0px)`);
          $speechbubble.addClass(
            `speech-bubble--is-visible${
              $form.length ? " speech-bubble--align-right" : ""
            }`
          );

          clearInterval(timer);
        }
      }, 1);
    }

    // Callback:after
    this.options.after.call(this, this.element);
  }

  closeAllSpeechBubbles(e) {
    if (e && typeof e !== "undefined") {
      let elements = $(
        ".close-button, .speech-bubble, .speech-bubble__text, .tooltip, .text-tooltip"
      );
      let isElement = elements.filter((i, elem) => $(elem).is(e.target)).length;

      if (isElement > 0) {
        return;
      }
    }

    $(".speech-bubble").each((i) => {
      if ($(`.speech-bubble:eq(${i})`).hasClass("speech-bubble--is-visible")) {
        $(`.speech-bubble:eq(${i})`).removeClass("speech-bubble--is-visible");
        $('.text-tooltip[class*="text-tooltip--is-active"]').removeClass(
          "text-tooltip--is-active"
        );
      }
    });
  }

  closeSpeechBubble(e) {
    e.stopImmediatePropagation();

    const $target = $(e.target);
    const $tooltip = $target.closest(".speech-bubble").prev(this.element);

    $tooltip.trigger("click", false);
  }
}

export { Tooltip };
