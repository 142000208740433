import { ready } from "./lib/utils";
import { plugin } from "./lib/plugin";
import { Accordion } from "./modules/accordion";
import { alert } from "./modules/alert";
import { forms } from "./modules/forms";
import { gallerySlider } from "./modules/gallery-slider";
import { globalHeader } from "./modules/global-header";
import { modal } from "./modules/modal";
import Table from "./modules/table";
import { timeline } from "./modules/timeline";
import { Tooltip } from "./modules/tooltip";

const main = {
  elem: {
    $accordion: $(".accordion:not(.accordion--has-rail-filters)"),
    $table: $(".table .table__wrapper"),
    $textTooltip: $(".text-tooltip"),
    $tooltip: $(".tooltip"),
  },

  init() {
    // Init plugins
    plugin("Accordion", Accordion);
    plugin("Table", Table);
    plugin("Tooltip", Tooltip);

    // Modules
    alert.init();
    forms.init();
    gallerySlider.init();
    globalHeader.init();
    modal.init();
    timeline.init();

    this.bindUIActions();
  },

  bindUIActions() {
    // Plugins
    this.elem.$accordion.Accordion();

    this.elem.$table.Table();

    this.elem.$tooltip.Tooltip({ type: "button" });

    this.elem.$textTooltip.Tooltip({
      type: "text",
      after(target) {
        $(target).toggleClass("text-tooltip--is-active");
      },
    });
  },
};

ready(function () {
  main.init();
});
