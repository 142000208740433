import { debounce, throttle } from "lodash-es";

const Table = class Table {
  constructor(element) {
    this.element = element;
    this.init();
  }

  init() {
    this.bindUIActions();
    this.onResize(); // On page load...
  }

  bindUIActions() {
    $(window).on("resize", debounce(this.onResize.bind(this), 100));

    $(this.element)
      .find(".table__container")
      .on("scroll", throttle(this.onScroll.bind(this), 100));
  }

  onResize() {
    let tableContainerWidth = $(this.element).outerWidth();
    let tableWidth = $(this.element)
      .find("table, .table-timeline")
      .outerWidth();

    let $table = $(this.element).parent(".table");

    $table.toggleClass(
      "table--has-border table--has-shadow",
      tableWidth > tableContainerWidth ? true : false
    );
  }

  onScroll(e) {
    let $target = $(e.target);
    let $table = $target.closest(".table");

    let width = $target.outerWidth();
    let scrollLeft = $target.scrollLeft();
    let scrollWidth = $target[0].scrollWidth;

    $table.toggleClass(
      "table--has-shadow",
      Math.round(scrollWidth - width) === scrollLeft ? false : true
    );
  }
};

export default Table;
