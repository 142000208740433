import { mql } from "../lib/utils";

const audienceNav = {
  $window: $(window),

  elem: {
    $audienceNav: $(".audience-nav:not(.audience-nav--is-mobile)"),
  },

  init() {
    this.$window.on("keydown", this.onKeyDown.bind(this));
  },

  onKeyDown(e) {
    const code = e.keyCode ? e.keyCode : e.which;

    let item;
    let i;

    if (mql.xlarge.matches) {
      if ($(document.activeElement).closest(".audience-nav").length > 0) {
        if (code === 37 || code === 39) {
          if (code === 37) {
            // Left arrow (37)
            item = $(document.activeElement)
              .closest(".audience-nav__item")
              .prev();
          } else if (code === 39) {
            // Right arrow (39)
            item = $(document.activeElement)
              .closest(".audience-nav__item")
              .next();
          }

          if (item.length > 0) {
            // Return to the first index or element within the list
            if (
              item.index() ===
              $(".audience-nav__item", this.elem.$audienceNav).length
            ) {
              item = $(".audience-nav__item", this.elem.$audienceNav).eq(0);
            }

            item.find(".audience-nav__link").focus();
          } else {
            i =
              item.index() === -1 && code === 37
                ? $("audience-nav__item", this.elem.$audienceNav).length - 1
                : 0;

            $(".audience-nav__item", this.elem.$audienceNav)
              .eq(i)
              .find(".audience-nav__link")
              .focus();
          }
        }
      }
    }
  },
};

export { audienceNav };
