import { arrow, scrollToTop } from "../lib/utils";
import { plugin } from "../lib/plugin";
import { Textarea } from "./textarea";

const forms = {
  validClass: "form__field--is-valid",
  invalidClass: "form__field--is-invalid",

  elem: {
    $body: $("body"),
    $checkbox: $("input:checkbox + label"),
    $radio: $("input:radio + label"),
    $select: $("select:not([multiple])"),
    $submit: $('input[type="submit"], button[type="submit"]'),
    $textarea: $("textarea"),
  },

  init() {
    // Init plugins
    plugin("Textarea", Textarea);

    this.bindUIActions();
    this.selectability();
  },

  bindUIActions() {
    this.elem.$body
      .on(
        "click",
        '.form input[type="submit"], .form button[type="submit"]',
        this.onClick.bind(this)
      )
      .on("input", "input", this.input.bind(this))
      .on("change", this.elem.$select, this.input.bind(this));

    this.elem.$checkbox.on("mouseleave", this.onMouseleave.bind(this));

    this.elem.$radio.on("mouseleave", this.onMouseleave.bind(this));

    this.elem.$submit.on("mouseleave", (e) => $(e.target).blur());

    this.elem.$textarea.Textarea();

    // UCIEM-633
    $(window).on("pageshow", () => {
      this.elem.$select.trigger("change");
    });
  },

  checkFormValid() {
    let $invalid = $(".form [required]:invalid");

    return $invalid.length > 0 ? false : true;
  },

  input(e) {
    let $input = e.length ? e : $(e.target);
    let isRequired =
      typeof $input.attr("required") !== undefined &&
      $input.attr("required") !== false;

    // For fields that are non-required but invalid: e.g. email, tel and etc. -sstacho
    if ($input.is(":valid") && $input.val().length === 0) {
      $input
        .parent(".form__field")
        .removeClass(`${this.validClass} ${this.invalidClass}`);

      return;
    }

    if (!isRequired) {
      return;
    }

    if ($input.is(":invalid")) {
      $input
        .parent(".form__field")
        .removeClass(this.validClass)
        .addClass(this.invalidClass);
    } else {
      $input
        .parent(".form__field")
        .removeClass(this.invalidClass)
        .addClass(this.validClass);
    }
  },

  onClick(e) {
    let $form = $(e.target).parents("form");

    if ($form.hasClass("mobile-search")) {
      return;
    }

    $form.addClass("js-was-submitted");

    if (!$form.hasClass("js-is-valid")) {
      e.preventDefault();
    }

    if (this.checkFormValid()) {
      $form.addClass("js-is-valid");
      $form[0].submit();
    } else {
      let $field = $("[required]:invalid").first().closest(".form__field");

      $("[required]").each((i) => {
        this.input($(`[required]:eq(${i.toString()})`));
      });

      scrollToTop($field, 425);
    }
  },

  onMouseleave(e) {
    $(`input[id="${$(e.target).attr("for")}"]`).blur();
  },

  selectability() {
    if (this.elem.$select.length === 0) {
      return;
    }

    this.elem.$select.selectability();

    let timer = setInterval(() => {
      let $selectability = $('.selectability[role="combobox"]');

      if ($selectability.length) {
        $selectability.each(function () {
          $(this).append(`${arrow()}`);
        });
      }

      clearInterval(timer);
    }, 1);

    $('.selectability[aria-disabled="true"]').attr("tabindex", "-1");
  },
};

export { forms };
