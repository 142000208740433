import "url-search-params-polyfill";

class Accordion {
  constructor(element, options) {
    this.defaults = {
      // Defaults
      duration: 425,
      easing: "swing",
      callback() {},
    };

    this.element = element;
    this.options = $.extend({}, this.defaults, options);

    this.init();
  }

  init() {
    this.bindUIActions();
    this.autoExpand();
    this.qsExpand();

    // Callback
    if (
      typeof this.options.callback !== "undefined" &&
      typeof this.options.callback === "function"
    ) {
      this.options.callback.call(this, this.element);
    }
  }

  autoExpand() {
    const _this = this; // Context
    const $accordions = $(this.element).children(".accordion__panel");

    if ($accordions.length > 0) {
      $accordions.each(function () {
        if ($(this).hasClass("accordion__panel--is-active")) {
          $(this)
            .find(".accordion__title")
            .children(".accordion__trigger")
            .first()
            .attr({
              "aria-expanded": "true",
            });

          $(this)
            .children(".accordion__content")
            .first()
            .attr("aria-hidden", "false")
            .slideToggle(_this.options.duration, _this.options.easing);
        }
      });
    }
  }

  bindUIActions() {
    $(this.element).on("click", ".accordion__trigger", this.onClick.bind(this));

    $(this.element).on(
      "keydown",
      ".accordion__trigger",
      this.onKeyDown.bind(this)
    );

    $(this.element).on("mouseleave", ".accordion__trigger", (e) => {
      $(e.target).blur();
    });
  }

  qsExpand(queryString = window.location.search) {
    let isFirst = true;
    let urlSearchParams = new URLSearchParams(queryString);

    if (!queryString || !urlSearchParams.has("accordionId")) {
      return;
    }

    const params = urlSearchParams.getAll("accordionId");

    params.forEach((param) => {
      let value = param;

      if (
        $(`.accordion__trigger[id="${value}"]`)
          .closest(".accordion__panel")
          .hasClass("accordion__panel--is-active") === false
      ) {
        $(`.accordion__trigger[id="${value}"]`).trigger("click");

        // Scroll to the first, "opened" accordion
        if (isFirst) {
          $("html, body").animate(
            { scrollTop: $(`.accordion__trigger[id="${value}"]`).offset().top },
            this.defaults.duration
          );
        }

        isFirst = false;
      }
    });
  }

  onClick(e) {
    e.preventDefault();

    const $content = $(e.target)
      .closest(".accordion__panel")
      .find(".accordion__content")
      .first();
    const $panel = $(e.target).closest(".accordion__panel");

    // Current target
    $(e.target).attr("aria-expanded", (i, currentValue) =>
      currentValue === "false" ? "true" : "false"
    );

    // Panel
    $panel.toggleClass("accordion__panel--is-active");

    // Content
    $content.attr("aria-hidden", (i, currentValue) =>
      currentValue === "true" ? "false" : "true"
    );

    $content.slideToggle(this.options.duration, this.options.easing);
  }

  onKeyDown(e) {
    const code = e.keyCode ? e.keyCode : e.which;

    // Down arrow
    if (code === 38) {
      $(e.target)
        .closest(".accordion__panel")
        .prev()
        .find(".accordion__trigger")
        .focus();
    }

    // Up arrow
    if (code === 40) {
      $(e.target)
        .closest(".accordion__panel")
        .next()
        .find(".accordion__trigger")
        .focus();
    }
  }
}

export { Accordion };
