import { closeButton } from "../lib/utils";

const modal = {
  options: {
    type: "video",
    hide_close: true,
    animation_speed: 425,
    after_open() {
      const closeModaalButton = closeButton(
        "close-button--secondary close-button--medium",
        "Close modal",
        "play-button"
      );

      $(".modaal-content, .modaal-video-container").prepend(closeModaalButton);

      let timer = setInterval(() => {
        let $closeButton = $('.close-button[data-selector="play-button"]');

        if ($closeButton.is(":focus") === false) {
          $closeButton.focus();
        }

        $('a, button:not(.close-button[data-selector="play-button"])').attr({
          "aria-hidden": "true",
          tabindex: "-1",
        });

        clearInterval(timer);
      }, 1);
    },
    after_close() {
      $(
        "a:not(.breadcrumbs__link--is-active, .rail-nav__link--is-active), button"
      ).removeAttr("aria-hidden", "tabindex");
    },
  },

  init() {
    this.bindUIActions();
  },

  bindUIActions() {
    $(document).on("click", ".close-button", function (e) {
      e.preventDefault();

      const modal = $(this).closest(".modaal-wrapper");
      const id = modal.attr("id");
      const button = $('[data-modaal-scope="' + id + '"]');

      $(button).modaal("close");
    });

    $(document).on("click", ".close-modal", function (e) {
      e.preventDefault();

      $(".close-button").trigger("click");
    });

    // (YouTube) video
    $('.play-button:not([data-modal-type="iframe"])').modaal(this.options);

    // iframe
    $('.play-button[data-modal-type="iframe"]').modaal(
      (() => {
        return $.extend({}, this.options, {
          type: "iframe",
          width: 1280,
          height: 720,
        });
      })()
    );

    $(".contact-card .open-modal").modaal({
      width: 836,
      hide_close: true,
      overlay_opacity: 0.5,
      after_open() {
        const closeModaalButton = closeButton(
          "close-button--secondary close-button--large",
          "Close modal",
          "open-modal"
        );

        $(".modaal-content, .modaal-video-container").prepend(
          closeModaalButton
        );

        let timer = setInterval(() => {
          let $closeButton = $(".close-button");

          if ($closeButton.is(":focus") === false) {
            $closeButton.focus();
          }

          clearInterval(timer);
        }, 1);
      },
    });
  },
};

export { modal };
