import { mql } from "../lib/utils";

const primaryNav = {
  $window: $(window),

  elem: {
    $primaryNav: $("#primary-nav"),
  },

  duration: 425,

  init() {
    this.bindUIActions();
  },

  bindUIActions() {
    $(document).on("click touchstart", this.onClick.bind(this));

    this.$window.on("keydown", this.onKeyDown.bind(this));
    this.$window.on("keyup", this.onKeyUp.bind(this));

    this.elem.$primaryNav
      .on("click", ".toggle-button", this.toggleChildNav.bind(this))
      .on("mouseleave", ".toggle-button", (e) => $(e.target).blur())
      .on("touchstart", ".primary-nav__link", this.touchOpen.bind(this));
  },

  onClick(e) {
    const _this = this;
    const $target = $(e.target);

    if (!$target.is("[class^='primary-nav']")) {
      $(".primary-nav__item").each(function () {
        let cls = "primary-nav__item--is-active";
        let $link = $(this).find(".primary-nav__link");

        if ($(this).hasClass(cls)) {
          $(this).removeClass(cls);
          if (
            $link.attr("aria-expanded") &&
            $link.attr("aria-expanded", "true")
          ) {
            _this.setExpandedState($link);
          }
        }
      });
    }
  },

  onKeyDown(e) {
    const code = e.keyCode ? e.keyCode : e.which;
    const isPrimaryNav =
      $(document.activeElement).closest("#primary-nav").length > 0;

    let item;
    let i;

    if (mql.large.matches) {
      if (isPrimaryNav) {
        if (code === 37 || code === 39) {
          // Left arrow (37)
          if (code === 37) {
            item = $(document.activeElement)
              .closest(".primary-nav__item")
              .prev();
            // Right arrow (39)
          } else if (code === 39) {
            item = $(document.activeElement)
              .closest(".primary-nav__item")
              .next();
          }

          $(".primary-nav__item")
            .not(item)
            .each(function () {
              $(this).removeClass("primary-nav__item--is-active");

              if ($(this).find(".primary-nav__child-list").length > 0) {
                $(this)
                  .find(".primary-nav__link")
                  .attr("aria-expanded", "false");
              }
            });

          if (item.length > 0) {
            // Return to the first index or element within the list
            if (item.index() === $(".primary-nav__item").length) {
              item = $(".primary-nav__item").eq(0);
            }

            item
              .addClass("primary-nav__item--is-active")
              .find(".primary-nav__link")
              .focus();

            if (item.find(".primary-nav__child-list").length > 0) {
              item.find(".primary-nav__link").attr("aria-expanded", "true");
            }
          } else {
            i =
              item.index() === -1 && code === 37
                ? $("primary-nav__item").length - 1
                : 0;

            $(".primary-nav__item")
              .eq(i)
              .addClass("primary-nav__item--is-active")
              .find(".primary-nav__link")
              .focus();

            if (
              $(".primary-nav__item").eq(i).find(".primary-nav__child-list")
                .length > 0
            ) {
              $(".primary-nav__item")
                .eq(i)
                .find(".primary-nav__link")
                .attr("aria-expanded", "true");
            }
          }
        }

        if (code === 38 || code === 40) {
          let hasChildList =
            $(document.activeElement).parent().find(".primary-nav__child-list")
              .length > 0;

          if (hasChildList) {
            e.preventDefault(); // Prevent the page from scrolling up or down

            let $childList = $(document.activeElement)
              .parent()
              .find(".primary-nav__child-list");

            $(document.activeElement)
              .parent()
              .addClass("primary-nav__item--is-active");

            $childList
              .find(".primary-nav__child-item")
              .eq(0)
              .find(".primary-nav__child-link")
              .focus();
          } else {
            e.preventDefault();

            // Up arrow (38)
            if (code === 38) {
              item = $(document.activeElement).parent().prev();
              // Down arrow (40)
            } else if (code === 40) {
              item = $(document.activeElement).parent().next();
            }

            if (item.length > 0) {
              // Return to the first index or element within the list
              if (item.index() === $(".primary-nav__child-item").length) {
                item = $(".primary-nav__child-item").eq(0);
              }

              item.find(".primary-nav__child-link").focus();
            } else {
              i =
                item.index() === -1 && code === 38
                  ? $(document.activeElement).parent().siblings().length
                  : 0;

              item = $(document.activeElement)
                .parent()
                .parent()
                .find(".primary-nav__child-item")
                .eq(i);
              item.find(".primary-nav__child-link").focus();
            }
          }
        }
      }
    }
  },

  onKeyUp(e) {
    const _this = this; // Context
    const code = e.keyCode ? e.keyCode : e.which;
    const isPrimaryNav =
      $(document.activeElement).closest("#primary-nav").length > 0;

    let item;

    if (mql.large.matches) {
      if (code === 9) {
        if (isPrimaryNav) {
          item = $(document.activeElement).closest(".primary-nav__item");

          $(".primary-nav__item")
            .not(item)
            .each(function () {
              let $item = $(this);
              let $link = $item.find(".primary-nav__link");

              $item.removeClass("primary-nav__item--is-active");

              if (
                $link.attr("aria-expanded") &&
                $link.attr("aria-expanded", "true")
              ) {
                _this.setExpandedState($link);
              }
            });

          $(document.activeElement)
            .closest(".primary-nav__item")
            .addClass("primary-nav__item--is-active");

          this.setExpandedState($(document.activeElement));
        } else {
          $(".primary-nav__item").each(function () {
            let $item = $(this);
            let $link = $item.find(".primary-nav__link");

            $item.removeClass("primary-nav__item--is-active");

            if (
              $link.attr("aria-expanded") &&
              $link.attr("aria-expanded", "true")
            ) {
              _this.setExpandedState($link);
            }
          });
        }
      }
    }
  },

  setExpandedState(e) {
    let element = typeof e.target !== typeof undefined ? $(e.target) : e;
    let expanded = element.attr("aria-expanded");

    if (typeof expanded !== typeof undefined && expanded !== false) {
      element.attr("aria-expanded", (i, val) =>
        val === "true" ? "false" : "true"
      );
    }
  },

  toggleChildNav(e) {
    const $target = $(e.target);
    const $childList = $target
      .parent(".primary-nav__item")
      .find(".primary-nav__child-list");

    $target
      .attr("aria-expanded", (i, txt) => (txt === "false" ? "true" : "false"))
      .attr("aria-label", (i, txt) =>
        txt.indexOf("expand") > -1
          ? txt.replace("expand", "collapse")
          : txt.replace("collapse", "expand")
      )
      .toggleClass("toggle-button--is-active");

    $childList.slideToggle(this.duration, "swing");
  },

  toggleChildNavFromParent($target) {
    const $parent = $target.parent(".primary-nav__item");

    if ($parent.hasClass("primary-nav__item--active")) {
      $parent.removeClass("primary-nav__item--active");
    } else {
      $parent.addClass("primary-nav__item--is-active");
    }
  },

  touchOpen(e) {
    const _this = this;

    if (mql.large.matches) {
      let tlink = $(e.target);
      let titem = tlink.parent(".primary-nav__item");

      if (
        !titem.hasClass("primary-nav__item--is-active") &&
        titem.hasClass("primary-nav__item--has-children")
      ) {
        e.preventDefault();

        $(".primary-nav__item").each(function () {
          let cls = "primary-nav__item--is-active";
          let $link = $(this).find(".primary-nav__link");

          if ($(this).hasClass(cls)) {
            $(this).removeClass(cls);
            if (
              $link.attr("aria-expanded") &&
              $link.attr("aria-expanded", "true")
            ) {
              _this.setExpandedState($link);
            }
          }
        });

        _this.toggleChildNavFromParent(tlink, 0);
      } else {
        $(".primary-nav__item").each(function () {
          let cls = "primary-nav__item--is-active";
          let $link = $(this).find(".primary-nav__link");

          if ($(this).hasClass(cls)) {
            $(this).removeClass(cls);
            if (
              $link.attr("aria-expanded") &&
              $link.attr("aria-expanded", "true")
            ) {
              _this.setExpandedState($link);
            }
          }
        });
      }
    }
  },
};

export { primaryNav };
