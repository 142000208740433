const search = {
  elem: {
    $search: $("#search"),
    $searchToggle: $("#search-toggle"),
  },

  init() {
    this.bindUIActions();
  },

  bindUIActions() {
    this.elem.$search.on("click", "#search-toggle", this.onClick.bind(this));
    this.elem.$search.on("mouseleave", "#search-toggle", (e) =>
      $(e.target).blur()
    );
  },

  onClick(e) {
    e.preventDefault();

    const $target = $(e.target);

    $target
      .toggleClass("search-toggle--is-active")
      .attr("aria-pressed", (i, txt) => (txt === "false" ? "true" : "false"));

    this.elem.$search.toggleClass("search--is-visible");

    // On transition end, redirect focus to the search input
    this.elem.$search.one(
      "transitionend webkitTransitionEnd oTransitionEnd otransitionend MSTransitionEnd",
      () => {
        const $input = this.elem.$search.find(".search__input");

        if (this.elem.$search.hasClass("search--is-visible")) {
          $input.attr("aria-hidden", "false").attr("tabindex", "0").focus();
        } else {
          $input.attr("aria-hidden", "true").attr("tabindex", "-1");
        }
      }
    );
  },
};

export { search };
